import React from "react";
import { Upload, message } from "antd";
import { s3Client } from "../../sdk/s3";

class S3Upload extends React.Component {
  state = {
    fileList: [],
  };

  componentDidMount() {
    // 处理初始值
    this.updateFileList(this.props.value);
  }

  componentDidUpdate(prevProps) {
    // 当 value 属性变化时更新文件列表
    if (prevProps.value !== this.props.value) {
      this.updateFileList(this.props.value);
    }
  }

  updateFileList = (value) => {
    if (!value) return;

    const fileList = Array.isArray(value) ? value : [value];
    const formattedFileList = fileList.map(file => ({
      uid: file.uid || `rc-upload-${Date.now()}`,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      percent: 100,
      status: 'done',
      url: file.url,
      response: {
        url: file.url,
      },
      originFileObj: file,
    }));

    this.setState({ fileList: formattedFileList });
  };

  handleUpload = async ({ file, onProgress, onSuccess, onError }) => {
    const key = `${Date.now()}-${file.name}`;
    try {
      const result = await s3Client.upload(key, file, {
        onProgress: progress => {
          onProgress({ percent: (progress.loaded / progress.total) * 100 });
        },
      });

      const fileInfo = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        percent: 100,
        status: "done",
        response: {
          url: result.url,
          key: key,
        },
        url: result.url,
        originFileObj: file,
      };

      onSuccess(fileInfo);
    } catch (err) {
      onError(err);
      message.error("上传失败");
    }
  };

  onChange = ({ file, fileList }) => {
    this.setState({ fileList });

    if (this.props.onChange) {
      const simplifiedFileList = fileList.map(f => ({
        uid: f.uid,
        name: f.name,
        size: f.size,
        type: f.type,
        lastModified: f.lastModified,
        lastModifiedDate: f.lastModifiedDate,
        percent: f.percent || 100,
        status: f.status,
        url: f.response?.url || f.url,
        response: f.response,
        originFileObj: f.originFileObj,
      }));

      const value = this.props.multiple 
        ? simplifiedFileList 
        : [simplifiedFileList[0]];
      
      this.props.onChange(value);
    }
  };

  render() {
    const { children, onChange, value, ...restProps } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        customRequest={this.handleUpload}
        onChange={this.onChange}
        fileList={fileList}
        {...restProps}
      >
        {/* 当非多文件上传且已有文件时，不显示上传按钮 */}
        {(!this.props.multiple && fileList.length > 0) ? null : children}
      </Upload>
    );
  }
}

export default S3Upload;