import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3_CONFIG } from "../config/s3.config.js";

class S3Service {
  constructor(config) {
    this.client = new S3Client({
      region: config.region || "default",
      endpoint: config.endpoint,
      credentials: {
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.accessKeySecret,
      },
      forcePathStyle: true,
    });
    this.bucket = config.bucket;
  }

  // async upload(key, fileContent, options = {}) {
  //   const command = new PutObjectCommand({
  //     Bucket: this.bucket,
  //     Key: key,
  //     Body: fileContent, // 直接使用 Buffer
  //     ContentType: options.ContentType, // 设置文件类型
  //   });
  
  //   try {
  //     const result = await this.client.send(command);
  //     return {
  //       name: key,
  //       url: await this.getFileUrl(key),
  //       etag: result.ETag,
  //     };
  //   } catch (error) {
  //     console.error("Upload failed:", error);
  //     throw error;
  //   }
  // }
  async upload(key, file, options = {}) {
    const command = new PutObjectCommand({
      Bucket: this.bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
    });

    try {
      const result = await this.client.send(command);
      return {
        name: key,
        url: await this.getFileUrl(key),
        etag: result.ETag,
      };
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  }

  async getFileUrl(key) {
    const command = new GetObjectCommand({
      Bucket: this.bucket,
      Key: key,
    });

    return await getSignedUrl(this.client, command, { expiresIn: 3600 });
  }

  async deleteFile(key) {
    const command = new DeleteObjectCommand({
      Bucket: this.bucket,
      Key: key,
    });

    try {
      await this.client.send(command);
    } catch (error) {
      console.error("Delete failed:", error);
      throw error;
    }
  }
}

export const s3Client = new S3Service(S3_CONFIG);
