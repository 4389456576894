import {
  S3_ENDPOINT,
  S3_REGION,
  S3_ACCESS_KEY_ID,
  S3_ACCESS_KEY_SECRET,
  S3_BUCKET,
} from "./env.js";

export const S3_CONFIG = {
  endpoint: S3_ENDPOINT,
  region: S3_REGION,
  accessKeyId: S3_ACCESS_KEY_ID,
  accessKeySecret: S3_ACCESS_KEY_SECRET,
  bucket: S3_BUCKET,
};
