import Auth from "@36node/auth-v0-sdk";
// import Auth from "./auth";
import Core from "@36node/catcm-livedata-core-sdk";
import { AUTH_BASE, CORE_BASE } from "../config/env";
import { denormalize } from "@36node/query-normalizr";

import fetch from "@36node/fetch";

const token = () => sessionStorage.getItem("token");

export const auth = new Auth({
  base: AUTH_BASE,
  token,
});

export const core = new Core({
  base: CORE_BASE,
  token,
});

/**
 * 根据key获取验证码
 */
export const getCaptcha = req => {
  const { key } = req || {};

  if (!key) throw new Error("key is required for getCaptcha");

  return fetch(`${AUTH_BASE}/captcha/${key}`, {
    method: "GET",
  });
};

auth.session.getLoginHistory = req => {
  const { query, headers } = req;

  return fetch(`${AUTH_BASE}/operations`, {
    method: "GET",
    query: denormalize(query),
    headers: { ...headers, Authorization: `Bearer ${token()}` },
  });
};

export const getOperations = req => {
  const { query, headers } = req;

  return fetch(`${CORE_BASE}/operations`, {
    method: "GET",
    query: denormalize(query),
    headers: { Authorization: `Bearer ${token()}`, ...headers },
  });
};
