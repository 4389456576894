import React from "react";
import {
  Form,
  Cascader,
  Input,
  Button,
  Icon,
  DatePicker,
  Select as SelectComponent,
  Tooltip,
} from "antd";
import S3Upload from "../s3-upload";
import { S3_CONFIG } from "../../config/s3.config";

import CountedTextarea from "../count-textarea";
// import { OSS_BASE } from "../../config";
import { LiveDataNewTypes, InputMessage } from "../../constants";
import { cityArray } from "./city";

const { RangePicker } = DatePicker;
const { Option } = SelectComponent;

export const Fields = {
  // 文本
  Text: ({
    form,
    initialValue,
    options,
    name,
    label,
    placeholder = InputMessage,
    message = InputMessage,
    required = false,
    layout = {},
    pattern,
    rules = [],
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
              pattern,
            },
            ...rules,
          ],
          initialValue,
          options,
        })(<Input placeholder={placeholder} {...rest} />)}
      </Form.Item>
    );
  },
  // 项目类型
  Type: ({
    form,
    name,
    options,
    label,
    message = InputMessage,
    placeholder = "请选择",
    initialValue,
    required = false,
    layout = {},
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <SelectComponent placeholder={placeholder} {...rest} allowClear>
            {LiveDataNewTypes.map(x => (
              <Option value={x.value} key={x.value}>
                {x.label}
              </Option>
            ))}
          </SelectComponent>
        )}
      </Form.Item>
    );
  },
  // 地区选择
  District: ({
    form,
    name,
    options,
    label,
    message = InputMessage,
    placeholder = InputMessage,
    initialValue,
    required = false,
    layout = {},
    data = cityArray,
    province,
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    let d = data;
    if (province) {
      d = d.filter(x => x.label.indexOf(province) !== -1);
    }
    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <Cascader placeholder={placeholder} options={d} {...rest}></Cascader>
        )}
      </Form.Item>
    );
  },
  // 多行文本
  TextArea: ({
    form,
    name,
    options,
    label,
    message = InputMessage,
    placeholder = InputMessage,
    initialValue,
    required = false,
    layout = {},
    autoSize = { minRows: 2, maxRows: 6 },
    maxLength = "500",
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item className="countedItem" label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <CountedTextarea
            placeholder={placeholder}
            autoSize={autoSize}
            maxLength={maxLength}
            {...rest}
          />
        )}
      </Form.Item>
    );
  },
  //上传
  File: ({
    form,
    initialValue,
    options,
    name,
    label,
    extra,
    title,
    message = InputMessage,
    required = false,
    layout = {},
    template,
    ...rest
  }) => {
    const { getFieldDecorator } = form;
    return (
      <Form.Item label={label} extra={extra} {...layout}>
        {getFieldDecorator(name, {
          rules: [{ required, message }],
          initialValue,
          options,
        })(
          <S3Upload {...S3_CONFIG} {...rest}>
            <Button>
              <Icon type="upload" /> {title}
            </Button>
          </S3Upload>
        )}
        {/* 恢复模板下载功能 */}
        {template && (
          <div>
            <a
              href={template}
              target="_blank"
              download
              rel="noopener noreferrer"
              style={{ marginLeft: 12 }}
            >
              模板下载
            </a>
            {template.indexOf(".pdf") !== -1 && (
              <Tooltip title="文件为PDF格式，在某些浏览器中将在新窗口中打开，可选择&quot;文件-&gt;另存为&quot;保存到本机。">
                <Icon style={{ marginLeft: 8 }} type="question-circle" />
              </Tooltip>
            )}
          </div>
        )}
      </Form.Item>
    );
  },
  // 日期
  Date: ({
    form,
    initialValue,
    rules,
    name,
    label,
    options,
    showTime = false,
    message = InputMessage,
    placeholder = InputMessage,
    required = false,
    layout = {},
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <DatePicker
            style={{ width: "100%" }}
            placeholder={placeholder}
            {...rest}
          />
        )}
      </Form.Item>
    );
  },
  // 日期范围
  DateRange: ({
    form,
    initialValue,
    rules,
    name,
    label,
    options,
    showTime = false,
    message = InputMessage,
    required = false,
    layout = {},
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <RangePicker
            showTime={showTime}
            style={{ width: "100%" }}
            placeholder={["起始日期", "结束日期"]}
            {...rest}
          />
        )}
      </Form.Item>
    );
  },
  // 下拉选择
  Select: ({
    form,
    initialValue,
    rules,
    name,
    label,
    options,
    showTime = false,
    message = InputMessage,
    placeholder = "请选择",
    required = false,
    layout = {},
    data = [],
    ...rest
  }) => {
    const { getFieldDecorator } = form;

    return (
      <Form.Item label={label} {...layout}>
        {getFieldDecorator(name, {
          rules: [
            {
              required,
              message,
            },
          ],
          initialValue,
          options,
        })(
          <SelectComponent placeholder={placeholder} allowClear {...rest}>
            {data.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.title}
              </Option>
            ))}
          </SelectComponent>
        )}
      </Form.Item>
    );
  },
};
