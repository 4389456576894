/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings
 *
 * APP_PORT=9527
 * APP_BASE_PATH=/v1
 * APP_JWT_PUBLIC_KEY=`a public key string`
 */

/* prettier-ignore */
/**
 *
 * @param {string} name envrionment name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name, init) {
  const key = `REACT_APP_${name.toUpperCase()}`;

  // 如果是浏览器环境，尝试从 window._runtime_ 中读取配置
  if (typeof window !== "undefined" && window._runtime_) {
    return window._runtime_[key] || process?.env?.[key] || init;
  }

  // 如果是 Node.js 环境，直接从 process.env 中读取配置
  return process?.env?.[key] || init;
}

export const VERSION = env("VERSION", "0.0.0");
export const ROOT_NS = env("ROOT_NS", "/catcm_uat");
export const TITLE = env("TITLE", "活态中医药传统知识数据库系统");
export const SYS_TYPE = env("SYS_TYPE", "LIVEDATA"); // LIVEDATA, TREATMENT

export const AUTH_BASE = env(
  "AUTH_BASE",
  "https://api.stargate.36node.com/auth/v0"
);
export const AUTH_PROVIDER = env("AUTH_PROVIDER", "61c174bbc4a169001220e7b9");
export const CORE_BASE = env(
  "CORE_BASE",
  "https://api.catcm-uat.36node.com/livedata/core/v0"
);

export const S3_ENDPOINT = env("S3_ENDPOINT", "https://eos-beijing-7.cmecloud.cn");
export const S3_REGION = env("S3_REGION", "default");
export const S3_ACCESS_KEY_ID = env("S3_ACCESS_KEY_ID", "97Q199I3LL95LTCXZ243");
export const S3_ACCESS_KEY_SECRET = env(
  "S3_ACCESS_KEY_SECRET",
  "YOO1Nfhqt1p7ia5dRFdlwU6qVQ5lhf7NmpHByE6y"
);
export const S3_BUCKET = env("S3_BUCKET", "36node-upload-test");
export const S3_BUCKET_DOMAIN = env(
  "S3_BUCKET_DOMAIN",
  "36node-upload-test.eos-beijing-7.cmecloud.cn"
);

export const MAX_FILE_SIZE = parseInt(env("MAX_FILE_SIZE", "1048576"), 10);
export const MAX_IMAGE_SIZE = parseInt(env("MAX_IMAGE_SIZE", "10240"), 10);

export const TOKEN = "token";

/* eslint-disable */
console.log(`VERSION:${VERSION}`);
console.log(`ROOT_NS:${ROOT_NS}`);
console.log(`TITLE:${TITLE}`);
console.log(`SYS_TYPE:${SYS_TYPE}`);
console.log(`AUTH_BASE:${AUTH_BASE}`);
console.log(`CORE_BASE:${CORE_BASE}`);
/* eslint-disable */